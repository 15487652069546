import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import careSourceLogo from "../assets/images/cs-logo-main.png";
import careAdvantageLogo from "../assets/images/careadvantage.png";
import careFriendsLogo from "../assets/images/carefriends.png";
import LayoutHome from "../components/layout/layout-home";
import { FONT_FAMILY } from "../styles/typography";
import { SILVER } from "../styles/colors";

const Wrapper = styled.div`
  max-width: 850px;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto 0 auto;
  text-align: center;
`;

const TopLink = styled.a`
  text-align: right;
  float: right;
  color: #ffffff;
  padding: 20px;
  font-family: ${FONT_FAMILY};
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`;

const Img = styled.img`
  height: 200px;
  @media (max-width: 769px) and (min-width: 320px) {
    height: 150px;
  }
`;

const Text = styled.div`
  color: #fff;
  text-align: center;
  font-size: 1.2rem;
  font-family: ${FONT_FAMILY};
  h1 {
    font-size: 2rem;
    @media (max-width: 769px) and (min-width: 320px) {
      font-size: 1.5rem;
    }
    display: inline;
    font-weight: normal;
  }
  @media (max-width: 769px) and (min-width: 320px) {
    font-size: 1.5rem;
  }
  font-weight: 300;
  margin: 20px;
  padding: 0;
  strong {
    color: white;
  }
  p {
    a {
      color: ${SILVER};
      :hover {
        color: white;
      }
    }
  }
`;

const LinksBox = styled.div`
  display: flex;
  margin-top: 80px;
  font-family: ${FONT_FAMILY};
  color: #fff;
  @media (max-width: 769px) and (min-width: 320px) {
    flex-direction: column;
    margin-top: 0;
  }
`;

const Box = styled.div`
  h2 {
    margin-bottom: 50px;
    @media (max-width: 769px) and (min-width: 320px) {
      margin-bottom: 25px;
    }
  }
  img {
    cursor: pointer;
    :hover {
      opacity: 0.7;
    }
  }
  width: 48%;
  @media (max-width: 769px) and (min-width: 320px) {
    width: 100%;
  }
`;

export default () => {
  return (
    <>
      <Helmet>
        <title>
          Care Source - Proven tools for sourcing, screening, and developing
          your frontline care
        </title>
        <link rel="canonical" href="https://caresource.com.au" />
        <meta
          name="description"
          content="Proven tools for sourcing, screening, and developing your frontline care, support and clinical staff"
        />
      </Helmet>
      <LayoutHome>
        <TopLink
          href="http://industryresearch.caresource.com.au/"
          title="care source industry research"
          target="_blank"
          rel="noreferrer"
          rel="noopener"
        >
          Industry Research
        </TopLink>
        <main>
          <Wrapper>
            <Text>
              <p>
                <Img src={careSourceLogo} alt="Caresource Logo" />
              </p>
              <h1>
                Proven tools for sourcing, screening, and developing your
                frontline care, support and clinical staff
              </h1>
            </Text>
            <LinksBox>
              <Box>
                <h2>Sourcing</h2>
                <a href="https://www.carefriends.com.au/" title="Care Friends">
                  <img
                    width="300"
                    src={careFriendsLogo}
                    alt="Care Friends Logo"
                  />
                </a>
              </Box>
              <Box>
                <h2>Behavioural Screening</h2>{" "}
                <a
                  href="https://www.careadvantage.com.au/"
                  title="Care Advantage"
                >
                  <img
                    width="300"
                    src={careAdvantageLogo}
                    alt="Care Advantage Logo"
                  />
                </a>
              </Box>
            </LinksBox>
          </Wrapper>
        </main>
      </LayoutHome>
    </>
  );
};
