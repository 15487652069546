import React, { useEffect } from "react";
import { createGlobalStyle } from "styled-components";
import ReactGA from "react-ga";
import StyledBackgroundSection from "./bg";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
  }`;

const LayoutHome = ({ children }) => {
  useEffect(() => {
    ReactGA.initialize("UA-75378819-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  });
  return (
    <>
      <GlobalStyle />
      <StyledBackgroundSection children={children} />
    </>
  );
};

export default LayoutHome;
